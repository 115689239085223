import React, { useState } from "react";
import PropTypes from "prop-types";

function CarouselClick({ onClick, children, className }) {
  const [startTracking, setStartTracking] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  let classList = [];
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );

  const triggerClick = () => {
    if (startTracking && !isMoving) {
      onClick();
    }
    setStartTracking(false);
    setIsMoving(false);
  };
  const triggerMouseDown = () => {
    setStartTracking(true);
  };
  const triggerMouseMove = () => {
    if (startTracking && !isMoving) {
      setIsMoving(true);
    }
  };

  return (
    <div
      className={classList.join(" ")}
      onClick={triggerClick}
      onKeyDown={triggerClick}
      onMouseDown={triggerMouseDown}
      onMouseMove={triggerMouseMove}
      role="button"
      tabIndex="0">
      {children}
    </div>
  );
}

CarouselClick.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.node,
};

CarouselClick.defaultProps = {
  onClick: null,
  className: "",
  children: null,
};

CarouselClick.displayName = "CarouselClick";

export default CarouselClick;
